import base64 from 'base-64';

export const encodeUserData = (data: any) => {
    const stringified = JSON.stringify(data);

    const encoded = base64.encode(stringified);

    return encodeURIComponent(encoded);
};

export const decodeUserDataString = (dataString: string) => {
    const encoded = decodeURIComponent(dataString);
    const stringified = base64.decode(encoded);

    return JSON.parse(stringified);
};

export enum RoomEntryType {
    CreateRoom = 'create',
    DeepLink = 'deep link',
    RoomList = 'room list',
    Direct = 'direct',
    Notification = 'notification',
    Paste = 'paste',
    DupeDisconnect = 'dupe disconnect',
}

export enum ShadowEventType {
    Identify = 'i',
    Track = 't',
    Page = 'p',
}

export default {
    encodeUserData,
    decodeUserDataString,
};
