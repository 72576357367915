import { createSlice } from '@reduxjs/toolkit';
import {
    roomSlice as sharedRoomSlice,
    initialState,
    extraReducers as sharedExtraReducers,
    RoomState,
} from 'packages/common/state/room';
import { HYDRATE } from 'next-redux-wrapper';

const { name, caseReducers } = sharedRoomSlice;

const roomSlice = createSlice({
    name,
    initialState,
    reducers: caseReducers,
    extraReducers: (builder) => {
        sharedExtraReducers(builder);
        builder.addCase(HYDRATE, (state, action: any) => {
            if (state.instance) {
                return {
                    ...state,
                    state: action.payload.room.state,
                };
            }

            return {
                ...state,
                ...action.payload.room,
            };
        });
    },
});

export type { RoomState };

export const {
    setID,
    setActiveSpeakerID,
    setConnected,
    setRoomState,
    setState,
    updateContentState,
    updateContentStreamMetadata,
    updateVoteStreakState,
    updateVoteState,
    setLastContentStream,
} = roomSlice.actions;

export default roomSlice.reducer;
