enum Colors {
    Border1 = '#403F3E',
    Border2 = '#2A2828',
    Border3 = '#A09D99',
    Bg1 = '#414040',
    Bg2 = '#353535',
    Bg3 = '#2C2C2C',
    Bg4 = '#1D1C1C',
    Bg5 = '#1F1C1C',
    Bg6 = '#2A2828',
    Bg7 = '#5D5D5D',
    Green1 = '#53AE72',
    Green2 = '#3B5642',
    Red1 = '#DA615C',
    Red2 = '#65413F',
    Red3 = '#DB3236',
    Pink1 = '#F8D6D7',
    Black1 = '#000000',
    White1 = '#FFFFFF',
    Text1 = '#FFFFFF',
    Text2 = '#9E9E9E',
    Text3 = '#A09D99',
    Text4 = '#696969',
    Yellow1 = '#F6BF4F',
    Yellow2 = '#7C6B48',
    Blue1 = '#3268db',
}

export default Colors;
