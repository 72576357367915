export enum ChatVisibilityMode {
    All,
    ActiveSpeaker,
    None,
}

export enum ChatOrientation {
    Horizontal,
    Vertical,
}

export type ResizeDirection = 'ne' | 'se' | 'sw' | 'nw' | 'n' | 'e' | 's' | 'w';

export type ChatPosition = {
    right: number;
    bottom: number;
    left: number;
    top: number;
};

export type RelativeChatPosition = {
    x: number;
    y: number;
};

export type ChatSize = {
    height: number;
    width: number;
};

export enum Edge {
    Top,
    Right,
    Bottom,
    Left,
}
