import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { denyMediaPermission, leftStage } from './sharedActions';
import { MediaInputDevice } from '../../base/types';
import { ChatVisibilityMode } from '../../video-chat/types';
import { joinRoom, leaveRoom, exitRoom, updateStage } from '../room/sharedActions';
import { User } from '../../api-client/types';
import { CamMode } from '../../room-client/common';

export interface ChatState {
    inRoom: boolean;
    muted: boolean;
    connected: boolean;
    camMode: CamMode;
    camera: MediaInputDevice;
    mic: MediaInputDevice;
    mode: ChatVisibilityMode;
    members: User['id'][];
    pendingInvites: User['id'][];
    pendingRequests: User['id'][];
    stageRequestGranted: boolean;
    volume: number;
}

export const initialState: ChatState = {
    inRoom: false,
    muted: false,
    connected: false,
    mode: ChatVisibilityMode.All,
    members: [],
    pendingInvites: [],
    pendingRequests: [],
    stageRequestGranted: false,
    camMode: CamMode.Standard,
    camera: {
        label: null,
        enabled: false,
        permission: false,
        deviceId: null,
        groupId: null,
    },
    mic: {
        label: null,
        enabled: false,
        permission: false,
        deviceId: null,
        groupId: null,
    },
    volume: 100,
};

const chatSlice = createSlice({
    name: 'chat',
    initialState,
    reducers: {
        connect(state) {
            state.connected = true;
        },
        disconnect(state) {
            state.connected = false;
        },
        grantMediaPermission(state, action: PayloadAction<{ type: 'mic' | 'camera' }>) {
            state[action.payload.type].permission = true;
        },
        enableCamera(state) {
            state.camera.enabled = true;
        },
        disableCamera(state) {
            state.camera.enabled = false;
        },
        enableMic(state) {
            state.mic.enabled = true;
        },
        disableMic(state) {
            state.mic.enabled = false;
        },
        setMediaDevices(
            state,
            action: PayloadAction<{ camera?: MediaDeviceInfo; mic?: MediaDeviceInfo }>
        ) {
            if (action.payload.mic) {
                state.mic = {
                    ...state.mic,
                    ...action.payload.mic.toJSON(),
                };
            }

            if (action.payload.camera) {
                state.camera = {
                    ...state.camera,
                    ...action.payload.camera.toJSON(),
                };
            }
        },
        changeMediaDevice(
            state,
            action: PayloadAction<{ type: 'mic' | 'camera'; device: Partial<MediaDeviceInfo> }>
        ) {
            const { type, device } = action.payload;
            state[type] = {
                enabled: true,
                permission: true,
                label: device.label,
                deviceId: device.deviceId,
                groupId: device.groupId,
            };
        },
        setCameraMode(state, action: PayloadAction<CamMode>) {
            state.camMode = action.payload;
        },
        toggleChatMuted(state, action: PayloadAction<boolean>) {
            state.muted = action.payload;
        },
        disableMediaDevice(state, action: PayloadAction<{ type: 'mic' | 'camera' }>) {
            state[action.payload.type].enabled = false;
        },
        toggleMediaDevice(state, action: PayloadAction<{ type: 'mic' | 'camera' }>) {
            if (!state[action.payload.type].enabled) {
                state[action.payload.type].permission = true;
            }

            state[action.payload.type].enabled = !state[action.payload.type].enabled;
        },
        changeChatMode(state, action: PayloadAction<ChatVisibilityMode>) {
            state.mode = action.payload;
        },
        clearStage(state) {
            state.members = [];
            state.pendingInvites = [];
            state.pendingRequests = [];
            state.camera.enabled = false;
            state.mic.enabled = false;
        },
        stageRequested(state) {
            state.stageRequestGranted = false;
        },
        stageRequestAccepted(state) {
            state.stageRequestGranted = true;
        },
        stageRequestDenied(state) {
            state.stageRequestGranted = false;
        },
        stageInviteRevoked(state) {
            state.stageRequestGranted = false;
        },
        cancelStageRequest(state) {
            state.stageRequestGranted = false;
        },
        changeChatVolume(state, action: PayloadAction<number>) {
            state.volume = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(denyMediaPermission, (state, action) => {
                state[action.payload.type].enabled = false;
                state[action.payload.type].permission = false;
            })
            .addCase(leftStage, (state) => {
                state.stageRequestGranted = false;
                state.camera.enabled = false;
                state.mic.enabled = false;
            })
            .addCase(joinRoom, (state) => {
                state.inRoom = true;
            })
            .addCase(updateStage, (state, action) => {
                state.members = Object.entries(action.payload.members)
                    .sort((a, b) => a[1] - b[1])
                    .map((entry) => entry[0]);
                state.pendingInvites = Object.entries(action.payload.pendingInvites)
                    .sort((a, b) => a[1] - b[1])
                    .map((entry) => entry[0]);
                state.pendingRequests = Object.entries(action.payload.pendingRequests)
                    .sort((a, b) => a[1] - b[1])
                    .map((entry) => entry[0]);
            })
            .addCase(leaveRoom, (state) => {
                state.inRoom = false;
                state.mic.enabled = false;
                state.camera.enabled = false;
            })
            .addCase(exitRoom, (state) => {
                return {
                    ...initialState,
                    mode: state.mode,
                    camera: state.camera,
                    mic: state.mic,
                    volume: state.volume,
                };
            });
    },
});

export const {
    connect,
    disconnect,
    grantMediaPermission,
    setMediaDevices,
    changeMediaDevice,
    disableMediaDevice,
    toggleMediaDevice,
    toggleChatMuted,
    changeChatMode,
    stageRequested,
    cancelStageRequest,
    stageRequestAccepted,
    stageRequestDenied,
    stageInviteRevoked,
    clearStage,
    enableCamera,
    disableCamera,
    enableMic,
    disableMic,
    changeChatVolume,
    setCameraMode,
} = chatSlice.actions;

export * from './sharedActions';

export default chatSlice.reducer;
