export const WHITELISTED_ERRORS = [
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded',
];

export const ignoreWhitelistedErrors = (whitelist: string[] = WHITELISTED_ERRORS) => {
    window.addEventListener(
        'error',
        (e) => {
            if (whitelist.includes(e.message)) {
                e.stopImmediatePropagation();
                e.preventDefault();
            }
        },
        { capture: true }
    );
};
