import { css, FlattenInterpolation, ThemeProps } from 'styled-components';
import { rem } from 'polished';

const FontWeights = {
    FontNormal: css`
        font-family: 'Grotesque Regular', Helvetica, Arial, sans-serif;
    `,
    FontMedium: css`
        font-family: 'Grotesque Medium', Helvetica, Arial, sans-serif;
    `,
    FontBold: css`
        font-family: 'Grotesque Bold', Helvetica, Arial, sans-serif;
    `,
};

const Texts = {
    Heading13: css`
        ${FontWeights.FontMedium}
        font-size: ${rem(13)};
        line-height: ${rem(16)};
        letter-spacing: ${rem(1.5)};
        color: ${({ theme }) => theme.palette.White};
    `,
    Heading13Standard: css`
        ${FontWeights.FontMedium}
        font-size: ${rem(13)};
        line-height: ${rem(16)};
        color: ${({ theme }) => theme.palette.White};
    `,
    Heading16: css`
        ${FontWeights.FontMedium}
        font-size: ${rem(16)};
        line-height: ${rem(20)};
        color: ${({ theme }) => theme.palette.White};
    `,
    Heading20: css`
        ${FontWeights.FontMedium}
        font-size: ${rem(20)};
        line-height: ${rem(24)};
        color: ${({ theme }) => theme.palette.White};
    `,
    Heading24: css`
        ${FontWeights.FontMedium}
        font-size: ${rem(24)};
        line-height: 120%;
        color: ${({ theme }) => theme.palette.White};
    `,
    Heading32: css`
        ${FontWeights.FontMedium}
        text-align: center;
        letter-spacing: ${rem(-1)};
        font-size: ${rem(32)};
        line-height: 120%;
        color: ${({ theme }) => theme.palette.White};
    `,
    Heading40: css`
        ${FontWeights.FontMedium}
        text-align: center;
        letter-spacing: ${rem(-1)};
        font-size: ${rem(40)};
        line-height: 120%;
        color: ${({ theme }) => theme.palette.White};
    `,
    Paragraph13: css`
        ${FontWeights.FontNormal}
        font-size: ${rem(13)};
        line-height: ${rem(16)};
        color: ${({ theme }) => theme.palette.LightGrey1};
    `,
    Paragraph16: css`
        ${FontWeights.FontNormal}
        font-size: ${rem(16)};
        line-height: ${rem(20)};
        color: ${({ theme }) => theme.palette.LightGrey1};
    `,
};

const Utilities = {
    EllipsisOverflow: css`
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    `,
    HideScrollbar: css`
        overflow: -moz-scrollbars-none;
        -ms-overflow-style: none;

        &::-webkit-scrollbar {
            display: none;
        }
    `,
};

type Style = FlattenInterpolation<ThemeProps<any>>;

interface Typography {
    FontNormal: Style;
    FontMedium: Style;
    FontBold: Style;
    Heading13: Style;
    Heading13Standard: Style;
    Heading16: Style;
    Heading20: Style;
    Heading24: Style;
    Heading32: Style;
    Heading40: Style;
    Paragraph13: Style;
    Paragraph16: Style;
    EllipsisOverflow: Style;
    HideScrollbar: Style;
}

const Typography: Typography = {
    ...FontWeights,
    ...Texts,
    ...Utilities,
};

export default Typography;
