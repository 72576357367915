import { useEffect, useState } from 'react';

interface useShowWhenVisible {
    visible: boolean;
    duration: number;
}

const useShowWhenVisible = ({ visible, duration }: useShowWhenVisible) => {
    const [isShowing, setIsShowing] = useState(false);

    useEffect(() => {
        if (visible) {
            setIsShowing(true);
        } else {
            const timeout = setTimeout(() => {
                setIsShowing(false);
            }, duration);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [visible, duration]);

    return isShowing;
};

export default useShowWhenVisible;
