import React from 'react';
import useShowWhenVisible from '../hooks/useShowWhenVisible';

interface TransitionVisible {
    visible: boolean;
    duration: number;
}

const TransitionVisible: React.FC<TransitionVisible> = (props) => {
    const { children, visible, duration } = props;

    const isShowing = useShowWhenVisible({ visible, duration });

    if (!isShowing) {
        return null;
    }

    return <>{children}</>;
};

export default TransitionVisible;
