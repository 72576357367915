import { createAction } from '@reduxjs/toolkit';
import { Room } from '../../api-client/types';
import { Participant, RoomState } from '../../room-client/common/typings';

export const setRoom = createAction<Room>('room/setRoom');
export const joinRoom = createAction('room/joinRoom');
export const leaveRoom = createAction('room/leaveRoom');
export const exitRoom = createAction('room/exitRoom');
export const updateRoomName = createAction<{ roomID: string; name: string }>('room/updateRoomName');
export const updateStage = createAction<RoomState['stage']>('room/updateStage');
