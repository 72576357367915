export enum ChannelType {
    Room = 'room',
    Support = 'support',
    Mod = 'mod',
}

export enum ReactionType {
    Fire = 'fire',
    Ice = 'ice',
    Laughing = 'laughing',
    Mad = 'mad',
    Eyes = 'eyes',
}

export type GiphyResponse = {
    height: string;
    width: string;
    size: string;
    url: string;
    frames: string;
};

export type GiphyPreview = {
    query: string;
    height: number;
    width: number;
    url: string;
    queryUrl: string;
};

export enum MessageType {
    Text = 'text',
    Reaction = 'reaction',
}

export interface ChatUser {
    name: string;
    id: string;
}

export type Message = {
    id: string;
    timestamp?: number;
    participantID: string;
    participantName: string;
    avatarURL: string;
    userID?: string;
    reactions: {
        up: ChatUser[];
        down: ChatUser[];
    };
    body: string;
};

export enum EphemeralMessageType {
    SupportPrompt = 'Support Prompt',
    SupportResolved = 'Support Resolved',
}
