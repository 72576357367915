import { NormalizedState, WithId } from './types';

export function normalize<T extends WithId>(items: T[]) {
    const normalized: NormalizedState<T> = {
        byId: {},
        allIds: [],
    };

    items.forEach((item) => {
        normalized.byId[item.id] = item;
        normalized.allIds.push(item.id);
    });

    return normalized;
}
