export enum PlaybackCookie {
    AccessToken = 'accessToken',
    DeviceInfo = 'deviceInfo',
}

interface CookieOptions {
    expires: Date;
}

export const getAuthCookieOptions = (): CookieOptions & any => ({
    path: '/',
    expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
    sameSite: 'lax',
    secure: true,
    httpOnly: false,
});

export const getDeviceCookieOptions = (): CookieOptions & any => ({
    path: '/',
    sameSite: 'lax',
    secure: true,
    httpOnly: false,
});
